import { graphql, Link } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import Quicklinks from '../components/Quicklinks';
import Resource from '../components/Resource';
import ScheduleAVisitForm from '../components/ScheduleAVisitForm';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import SurveyCallout from '../components/SurveyCallout';
import Video from '../components/Video';
import { getKeyedResources } from '../utils/resource-helper';

const textOverlay = (
  <div className="educators__text_overlay_container">
    <Container fullWidth>
      <div className="educators__text_overlay_info">Educator resources</div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    Teachers learning from their students is a way of crowdsourcing data.
  </span>
);

const Educators = ({ data }) => {
  const {
    dataInMyDay,
    educatorGuide,
    educatorsAccessToHealth,
    educatorsWomenGenderEquity,
    educatorsEnvironmentalJustice,
    educatorsSocialMediaAndMisinformation,
    educatorsUsingCensusSnapshot,
    educatorsUnderstandingElectionCycle,
    educatorsDataDuringHolidays,
    educatorsBigDataBigGame,
    educatorsDataGetsGreen,
    premiumProducts,
    winningSmile,
    millennialMovement,
    clientSolutionsExecutiveRec,
    clientSolutionsRec,
  } = getKeyedResources(data);

  return (
    <Layout title="Educators">
      <Hero
        mediaSource="img-lead-triangle-educator-resources.png"
        textOverlay={textOverlay}
      />
      <Section className="educators__callout-section section--gray">
        <Container fullWidth>
          <Row className="educators__callout-row">
            <Column largeOffset={1} large={10}>
              <Callout
                className="educators__callout"
                containerClassName="educators__callout-container"
              >
                Activate data exploration in class and afterschool
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={10}>
              <p className="text-sans-serif-large">
                Once your students start to see how meaningful data can be
                conjured up from every aspect of life, their view of the world
                will be changed forever. Access standards-aligned resources that
                add essential context to timely topics while exploring
                data-driven innovation across all industries. These ready-to-use
                resources can be integrated with your SSO for easy
                implementation.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Quicklinks
                links={[
                  {
                    target: '#student-interactive',
                    label: 'Student interactive',
                  },
                  {
                    target: '#curriculum-connectors',
                    label: 'Curriculum connectors',
                  },
                  {
                    target: '#classroom-activities',
                    label: 'Classroom activities',
                  },
                  {
                    target: '#educator-webinar',
                    label: 'Educator webinar',
                  },
                  {
                    target: '#request-a-visit',
                    label: 'Request a visit',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="section--gray" id="student-interactive">
        <Container>
          <Row className={'mb-0'}>
            <Column size={12} className="educators__section-header">
              Student interactive
            </Column>
          </Row>
          <Row className="educators__section-wrapper pt-2">
            <Row>
              <Column size={8}>
                <Resource
                  title={dataInMyDay.title}
                  subject={dataInMyDay.subject}
                  audience={dataInMyDay.audience}
                  duration={dataInMyDay.duration}
                  description={dataInMyDay.description}
                  img={dataInMyDay.img}
                  actions={dataInMyDay.actions}
                />
              </Column>
              <Column size={4}>
                <Resource
                  title={educatorGuide.title}
                  description={educatorGuide.description}
                  img={educatorGuide.img}
                  actions={educatorGuide.actions}
                />
              </Column>
            </Row>
          </Row>
        </Container>
      </Section>
      <Section id="curriculum-connectors">
        <Container>
          <Row>
            <Column size={9}>
              <h2>Curriculum connectors</h2>
              <p className="text-sans-serif">
                These exploratory resources provide beginner and
                intermediate-level activity ideas tied to a specific theme, for
                students in Grades 6–12. Discover real-world data sets that you
                can integrate into your curriculum.{' '}
                <strong>
                  *For Spanish-language resources that you can assign or use to
                  support learning, please scroll down to the editable student
                  presentations and datasets below, or explore our{' '}
                  <Link to="/students">student resources page</Link>.
                </strong>
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={educatorsEnvironmentalJustice.tophat}
                title={educatorsEnvironmentalJustice.title}
                subject={educatorsEnvironmentalJustice.subject}
                duration={educatorsEnvironmentalJustice.duration}
                className={educatorsEnvironmentalJustice.className}
                audience={educatorsEnvironmentalJustice.audience}
                description={educatorsEnvironmentalJustice.description}
                img={educatorsEnvironmentalJustice.img}
                actions={educatorsEnvironmentalJustice.actions}
                ribbon={educatorsEnvironmentalJustice.ribbon}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsAccessToHealth.tophat}
                ribbon={educatorsAccessToHealth.ribbon}
                title={educatorsAccessToHealth.title}
                subject={educatorsAccessToHealth.subject}
                duration={educatorsAccessToHealth.duration}
                className={educatorsAccessToHealth.className}
                audience={educatorsAccessToHealth.audience}
                description={educatorsAccessToHealth.description}
                img={educatorsAccessToHealth.img}
                actions={educatorsAccessToHealth.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsSocialMediaAndMisinformation.tophat}
                title={educatorsSocialMediaAndMisinformation.title}
                subject={educatorsSocialMediaAndMisinformation.subject}
                duration={educatorsSocialMediaAndMisinformation.duration}
                className={educatorsSocialMediaAndMisinformation.className}
                audience={educatorsSocialMediaAndMisinformation.audience}
                description={educatorsSocialMediaAndMisinformation.description}
                img={educatorsSocialMediaAndMisinformation.img}
                actions={educatorsSocialMediaAndMisinformation.actions}
                ribbon={educatorsSocialMediaAndMisinformation.ribbon}
              />
            </Column>
          </Row>
          <Row>
            <Column id="women-gender-equity" size={4}>
              <Resource
                tophat={educatorsWomenGenderEquity.tophat}
                title={educatorsWomenGenderEquity.title}
                subject={educatorsWomenGenderEquity.subject}
                duration={educatorsWomenGenderEquity.duration}
                className={educatorsWomenGenderEquity.className}
                audience={educatorsWomenGenderEquity.audience}
                description={educatorsWomenGenderEquity.description}
                img={educatorsWomenGenderEquity.img}
                actions={educatorsWomenGenderEquity.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsUsingCensusSnapshot.tophat}
                title={educatorsUsingCensusSnapshot.title}
                subject={educatorsUsingCensusSnapshot.subject}
                duration={educatorsUsingCensusSnapshot.duration}
                className={educatorsUsingCensusSnapshot.className}
                audience={educatorsUsingCensusSnapshot.audience}
                description={educatorsUsingCensusSnapshot.description}
                img={educatorsUsingCensusSnapshot.img}
                actions={educatorsUsingCensusSnapshot.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsUnderstandingElectionCycle.tophat}
                title={educatorsUnderstandingElectionCycle.title}
                subject={educatorsUnderstandingElectionCycle.subject}
                duration={educatorsUnderstandingElectionCycle.duration}
                className={educatorsUnderstandingElectionCycle.className}
                audience={educatorsUnderstandingElectionCycle.audience}
                description={educatorsUnderstandingElectionCycle.description}
                img={educatorsUnderstandingElectionCycle.img}
                actions={educatorsUnderstandingElectionCycle.actions}
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={educatorsDataDuringHolidays.tophat}
                title={educatorsDataDuringHolidays.title}
                subject={educatorsDataDuringHolidays.subject}
                duration={educatorsDataDuringHolidays.duration}
                className={educatorsDataDuringHolidays.className}
                audience={educatorsDataDuringHolidays.audience}
                description={educatorsDataDuringHolidays.description}
                img={educatorsDataDuringHolidays.img}
                actions={educatorsDataDuringHolidays.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsBigDataBigGame.tophat}
                title={educatorsBigDataBigGame.title}
                subject={educatorsBigDataBigGame.subject}
                duration={educatorsBigDataBigGame.duration}
                className={educatorsBigDataBigGame.className}
                audience={educatorsBigDataBigGame.audience}
                description={educatorsBigDataBigGame.description}
                img={educatorsBigDataBigGame.img}
                actions={educatorsBigDataBigGame.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={educatorsDataGetsGreen.tophat}
                title={educatorsDataGetsGreen.title}
                subject={educatorsDataGetsGreen.subject}
                duration={educatorsDataGetsGreen.duration}
                className={educatorsDataGetsGreen.className}
                audience={educatorsDataGetsGreen.audience}
                description={educatorsDataGetsGreen.description}
                img={educatorsDataGetsGreen.img}
                actions={educatorsDataGetsGreen.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section id="classroom-activities">
        <Container>
          <Row>
            <Column size={9}>
              <h2>Classroom activities</h2>
              <p className="text-sans-serif">
                These hands-on activities are flexibly designed for any learning
                environment. Each activity lasts approximately 45 minutes, and
                is intended for use across multiple content areas for students
                in Grades 6–8.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={premiumProducts.tophat}
                title={premiumProducts.title}
                subject={premiumProducts.subject}
                duration={premiumProducts.duration}
                className={premiumProducts.className}
                audience={premiumProducts.audience}
                description={premiumProducts.description}
                img={premiumProducts.img}
                actions={premiumProducts.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={winningSmile.tophat}
                title={winningSmile.title}
                subject={winningSmile.subject}
                duration={winningSmile.duration}
                className={winningSmile.className}
                audience={winningSmile.audience}
                description={winningSmile.description}
                img={winningSmile.img}
                actions={winningSmile.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={millennialMovement.tophat}
                title={millennialMovement.title}
                subject={millennialMovement.subject}
                duration={millennialMovement.duration}
                className={millennialMovement.className}
                audience={millennialMovement.audience}
                description={millennialMovement.description}
                img={millennialMovement.img}
                actions={millennialMovement.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-0">
        <Container>
          <Row id="educator-webinar" className="mb-4">
            <Column size={4}>
              <Image filename="promo-ed-webinar@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">Educator webinar</h2>
              <p className="text-sans-serif">
                Looking for ways to adapt lessons for a virtual classroom?
                <span className="text-bold"> DISCOVER DATA….Remotely!</span> is
                the latest edition in our webinar series. With overviews on how
                to use popular digital tools with DISCOVER DATA resources, our
                goal is to provide you with concrete ideas for engaging your
                students virtually.
              </p>
              <p className="mb-0 text-sans-serif">
                <strong>Webinar highlights</strong>
              </p>
              <ul className="pl-2 text-sans-serif">
                <li>
                  Overview the key points of FERPA (The Family Educational
                  Rights and Privacy Act)
                </li>
                <li>
                  Our Top 10 digital tools for using DISCOVER DATA remotely
                </li>
                <li>
                  Making Career Connections: Virtually connecting your class
                  with real-world data professionals
                </li>
              </ul>

              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="pl-1">Watch now</span>
                  </Button>
                }
              >
                <Video
                  guidSrc="5eead242-1cf8-41a7-89b4-8f811b653a3e"
                  poster="video-poster-webinar@2x.jpg"
                  video={true}
                />
              </Modal>
            </Column>
          </Row>
          <Row id="request-a-visit">
            <Column size={4}>
              <Image filename="promo-request-a-visit@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">
                Virtually connect your class with a data expert
              </h2>
              <p className="text-sans-serif">
                Show students the power of data in action by putting them
                face-to-face with an inspiring real-world data expert, who will
                lead your class through a unique digital lesson.
              </p>
              <div className="mb-1">
                <Modal
                  trigger={
                    <Button>
                      <Icon name="externallink" />
                      <span className="ml-1">Request a visit</span>
                    </Button>
                  }
                >
                  <ScheduleAVisitForm />
                </Modal>
              </div>
              <div>
                <Button
                  target="_"
                  className={'button-secondary'}
                  to="/sites/default/files/Curriculum/Nielsen-DiscoverData-VolunteerEdGuide-V2.0.pdf"
                >
                  <Icon name="externallink" />
                  <span className="ml-1">Volunteer visit guide</span>
                </Button>
              </div>
              {/* <div>
                <Button
                  to="/sites/default/files/documents/Nielsen-Interactive-Educator-Guide.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  isSecondary={true}
                >
                  <Icon name="externallink" />
                  <span className="pl-1">Educator guide</span>
                </Button>
              </div> */}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={`section--gray`}>
        <Container>
          <Row>
            <Column size={9}>
              <h2>Share data careers with your class</h2>
              <p className="text-sans-serif">
                Whether in place of, or in addition to connecting with a
                data-professional, Discover Data’s Video Career profiles serve
                to provide a personalized connection to careers in data. In
                these videos, students meet six diverse young professionals as
                they share the unique ways they work with data – in both their
                personal lives and at work.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              <Resource
                title={clientSolutionsExecutiveRec.title}
                className={clientSolutionsExecutiveRec.className}
                description={clientSolutionsExecutiveRec.description}
                img={clientSolutionsExecutiveRec.img}
                actions={clientSolutionsExecutiveRec.actions}
                linkResource
              />
            </Column>
            <Column size={6}>
              <Resource
                title={clientSolutionsRec.title}
                className={clientSolutionsRec.className}
                description={clientSolutionsRec.description}
                img={clientSolutionsRec.img}
                actions={clientSolutionsRec.actions}
                linkResource
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <SurveyCallout />
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-educators@2x.jpg"
          content={subfooterContent}
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query EducatorResources {
    allResourcesJson(filter: { pages: { in: "educators" } }) {
      edges {
        node {
          slug
          tophat
          ribbon
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            link
            module
            moduleName
            download
            noIcon
            asLink
            dropdown
            disabled
            pptx
            googleSlides
            segmentData {
              clickLabel
              clickType
              format
            }
            actions {
              label
              metadata
              module
              download
              noIcon
              asLink
              disabled
              pptx
              googleSlides
              segmentData {
                clickLabel
                clickType
                format
              }
            }
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default Educators;
